import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
// import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net'; // Import DataTables jQuery plugin

const Doctor_details = () => {
    const [user, setUser] = useState([]);


    const loadUser = async () => {
        const result = await axios.get('http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/doctors/doctor_table.php');
        setUser(result.data.phpresult);
        // console.log(result.data.phpresult);

        // Initialize DataTable after the data has been set
        $(document).ready(function () {
            $('#example').DataTable({
                paging: true,            // Pagination
                searching: true,         // Search box
                scrollY: '400px',        // Vertical Scroll
                scrollX: true,           // Horizontal Scroll
                scrollCollapse: true,    // Allow scrolling for smaller tables
            });
        });
    };

    useEffect(() => {
        loadUser();
    }, []);

    return (
        <div className="container mt-5">
            <div className="table-responsive">
                <table id="example" className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Gender</th>
                            <th>Birth Date</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Specialization</th>
                            <th>Registration Number</th>
                            <th>Country</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Post Code</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user.map((res, index) => (
                            <tr key={index}>
                                <td>{res.first_name}</td>
                                <td>{res.last_name}</td>
                                <td>{res.gender}</td>
                                <td>{res.date_of_birth}</td>
                                <td>{res.email}</td>
                                <td>{res.phone_number}</td>
                                <td>{res.specialization}</td>
                                <td>{res.registration_number}</td>
                                <td>{res.country}</td>
                                <td>{res.state}</td>
                                <td>{res.city}</td>
                                <td>{res.postal_code}</td>
                                <td>{res.address}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Doctor_details;
