import React from 'react';

// jsx file
import PatientReg from './components/patient_reg';
import DoctorReg from './components/doctor_reg';
import PatientDetails from './components/patient_details';
// import ExtraLogin from './components/extra_login';
import DoctorDetails from './components/doctor_details';

import CardExe from './components/cards/card_exe';
import Exercise from './components/cards/exercise';

// css
import './css/patient_reg.css';
// import './css/patient_details.css';



import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (

<Router>
      <div>
        <Routes>
          {/* read first page */}
          <Route path="/" element={<PatientReg />} />
          <Route path="Doctor_Reg" element={<DoctorReg />} />
          <Route path="Patient_Details" element={<PatientDetails />} />
          {/* <Route path="Extra_Login" element={<ExtraLogin />} /> */}
          <Route path="doctor_details" element={<DoctorDetails />} />

          <Route path="card_exe" element={<CardExe />} />
          <Route path="/card_exe/exercise" element={<Exercise />} />

        </Routes>
      </div>
    </Router>
  
  );
}

export default App;
