
import React, { useState } from 'react';
import SIGNIN from '../image/signin.jpg';
// data mokalva mate
import axios from 'axios';
// redirect mate
import { useNavigate } from 'react-router-dom';
// alert use karva
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/patient_reg.css';
//Error : data inser thay chee pan message not insert no ave che (email different hove joye)

export default function DoctorRegistration() {
  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    gender:'',
    date_of_birth:'',
    email: '',
    phone_number:'',
    password_hash: '',
    specialization:'',
    registration_number:'',
    country:'',
    state:'',
    city:'',
    postal_code:'',
    address:''
  });

  const navigate = useNavigate(); // Initialize useNavigate hook

      // input change value in name
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // ------------validation----------

        // Check if any field is empty
        for (const key in inputs) {
          if (inputs[key].trim() === '') {
            toast.error(`${key.replace('_', ' ')} can not be empty.`);
            return;
          }
        }
        
       // Validate the phone number
    if (!/^\d{10}$/.test(inputs.phone_number)) {
      // toast.error('Phone number must be exactly 10 digits.');
      toast.error('Phone number is a not set.');
      return;
    }

    if (!/^\d{6}$/.test(inputs.postal_code)) {
      // toast.error('Pincode must be exactly 6 digits.');
      toast.error('Pincode is a not set.');
      return;
    }

     // Validate the password strength
     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
     if (!passwordRegex.test(inputs.password_hash)) {
      //  toast.error('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
      toast.error('Password is a not set.');
       return;
     }
 

    //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/doctors/create.php
    try {
      const response = await axios.post('./api/doctors/create.php',inputs);
  
      // console.log("response",response)
      // console.log('response.data.status',response.data);

      if(response.data.status === true){
        
        toast.success('Account is a created successfully !');
      // Redirect to sign-in page after successful account creation
            setTimeout(() => {
            //toggleForm() redirct in sign in(login)
            navigate('../');
            },6000);
      }else{
        toast.error('Account is a not created !');
      }
       
    } catch (error) {
      // console.error('Error submitting form:', error);
      toast.error('Failed to create doctor account. Please try again.');
    }
  };

  return (
    <>
    <ToastContainer/>
      <div>
        <section>
          <div className="container">
            <div className="user signinBx">
              <div className="imgBx">
                <img
                  src={SIGNIN}
                  style={{ width: '80%', height: '40%', marginTop: '20%', marginLeft: '10%', border: '3px solid black' }}
                  alt=""
                />
              </div>
              <div className="formBx">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <h2>Create a doctor's account</h2>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text" 
                        placeholder="First Name"
                        name='first_name'
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name='last_name'
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                       <select name='gender' onChange={handleChange}>
                           <option>Select the Gender : </option>
                           <option value="Male">Male</option>
                           <option value="Female">Female</option>
                           <option value="Other">Other</option>
                       </select>
                   </div>
                   <div className="col-md-6 form-group">
                      <input
                        type="date"
                        placeholder="Date of Birth"
                        name='date_of_birth'
                        onChange={handleChange}
                      />
                    </div>
                    
                    <div className="col-md-12 form-group">
                      <input
                        type="email"
                        placeholder="Email Address"
                        name='email'
                        onChange={handleChange}
                      />
                      <input
                        type="number"
                        placeholder="Phone Number"
                        name='phone_number'
                        onChange={handleChange}
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        name='password_hash'
                        onChange={handleChange}
                      />
                       
                      <select name='specialization'
                        onChange={handleChange}>
                           <option>Select the specialization : </option>
                           <option value="Cardiology">Cardiology</option>
                           <option value="Orthopedic">Orthopedic</option>
                           <option value="Multipale">Multipale</option>
                      </select>
    
                      <input
                        type="text"
                        placeholder="Registration Number"
                        name='registration_number'
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        placeholder="Country"
                        name='country'
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        placeholder="State"
                        name='state'
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        placeholder="City"
                        name='city'
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="number"
                        placeholder="Postal Code"
                        name='postal_code'
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-12 form-group">
                      <input
                        type="text"
                        placeholder="Address"
                        name='address'
                        onChange={handleChange}
                      />
                    </div>
                    
                  </div>
                  <input type="submit" value="Sign up" />
                  
                  <p className="signup">
                    Already have an account? <a href="../">Sign in.</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
