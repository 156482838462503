import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../../css/exercise.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";  // Import right arrow icon

const ResponsiveImageSlider = () => {
  const [exercise, setExercise] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // State to track current image index

  const navigate = useNavigate();

      //***************Token********************* */
     //check the token not tok then redirect the login(login time set token)
     useEffect(() => {
      // Get the token from localStorage 
      if (!localStorage.getItem('authToken')) {
        // If no token, redirect to Page 1 or login page
        navigate('../');
      }
    }, []);

    //************************************ */

  //--------------session Table mathee avse Patient Id --------------------------------

  const [storedId, setStoredId] = useState(null);

  // Retrieve patient ID from session storage
  useEffect(() => {
    const storedId = sessionStorage.getItem('patientId'); 
    setStoredId(storedId);
    // console.log("Second page: ", storedId); // Log the stored ID
    // console.log('strore',storedId)
  }, []); 
  //-------------------------------------------------


      // Function to load exercise data
  const loadUser = async () => {
    try {
      if (storedId) // Exit if storedId is not available
      // console.log('strore',storedId)
      {
        //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/patient/select_exercies.php
        const result = await axios.get('../api/patient/select_exercies.php', {
        params:{patient_id:storedId}
        });
        // console.log('Exercise data:', result.data.ExercisesResult);
        setExercise(result.data.ExercisesResult);
    }
    } catch (error) {
      // console.error('Error fetching exercise data:', error);
    }
  };

  // Call loadUser when storedId is available
  useEffect(() => {
    if (storedId) {
      loadUser(); // Load data only when storedId is set
    }
  }, [storedId]); // Trigger when storedId changes



  // Handle Next button click
  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % exercise.length); // Move to next image
  };

  // Handle Previous button click
  const handlePrevClick = () => {
    setCurrentIndex((currentIndex - 1 + exercise.length) % exercise.length); // Move to previous image
  };

  // Show loading message until data is available
  if (exercise.length === 0) {
    return <div>Loading...</div>;
  }

  //****** Logout ***** */

  const HandleLogout = () =>{  
   
  Swal.fire({
    title: "Are you sure you want to logout?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Logout"
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "Logged out!",
        text: "You have been successfully logged out.",
        icon: "success"
      }).then(() => {
        navigate('../');  // Redirect only after the confirmation
      });
    }
  });

  // Remove the localstorage token (authToken name same hovu joye)
  localStorage.removeItem('authToken');

};


  return (
    <div className='exercise'>
    <div className="card-slider-container">

    {/* ********* logout ********* */}

    <div className="container-fluid">
      <div className="row">
        <div className="col d-flex justify-content-end mt-2 mb-2">
          <button type="button" onClick={HandleLogout} className="btn btn-outline-danger">Logout</button>
        </div>
      </div>
    </div>

      {/* ************************ */}

      <div className="card mx-auto" style={{ maxWidth: '900px' }}>
        <div className="card-header text-center">
          {/* Optional: Title or other information about the slide */}
          <h5>{exercise[currentIndex].title || `Exercise ${currentIndex + 1}`}</h5>
        </div>
        
        <div className="card-body text-center">
          {/* Show only the current exercise image fetched from the database */}
          <img
            src={`${process.env.PUBLIC_URL}/img/${exercise[currentIndex].img_path}`} 
            className="card-img-top"
            alt={`Slide ${currentIndex + 1}`}
            style={{ maxHeight: '400px', objectFit: 'cover' }} // Adjust image size and keep aspect ratio
          />
          
          {/* Optional: Description or exercise name fetched from the database */}
          <p className="mt-3">{exercise[currentIndex].ex_name || 'No description available'}</p>
        </div>

        {/* Controls buttons */}
        <div className="d-flex justify-content-between mt-3 mb-3">
          {/* Previous Button */}
          <button className="btn btn-outline" onClick={handlePrevClick}>
          <SlArrowLeft />  {/* Use right arrow for previous button */}
          </button>

          {/* Current Index / Total Count */}
          <p>{currentIndex + 1} / {exercise.length}</p>

          {/* Next Button */}
          <button className="btn btn-outline" onClick={handleNextClick}>
          <SlArrowRight/>  {/* Use left arrow for next button */}
          </button>
        </div>
      </div>
    </div>
    </div>

  );
};

export default ResponsiveImageSlider;
