import '../css/patient_details.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'datatables.net-dt/css/jquery.dataTables.min.css'; // DataTables CSS
import 'datatables.net'; // DataTables JS
import 'datatables.net-responsive'; // DataTables responsive plugin

export default function PatientTable () {

    const navigate = useNavigate();

      //check the token not tok then redirect the login(login time set token)
      useEffect(() => {
          // Get the token from localStorage 
          if (!localStorage.getItem('authToken')) {
            // If no token, redirect to Page 1 or login page
            navigate('../');
          }
        }, []);

  //   // ---------var store in session patient id------------
  const patientIdFunction = (e) => {

    const p_id = e.target.value;
    sessionStorage.setItem('patientId', p_id);
    // alert(p_id); 
    // console.log(p_id);
    navigate('../card_exe');
  };    
  

  //------------------Table-------------------------------

  const [user, setUser] = useState([]);

  // Function to load user data
  const loadUser = async () => {
    //http://localhost/PHP/REACT_PROJECTS/patient_exercies/src/api/patient/patient_details.php
    try {
      const result = await axios.get('./api/patient/patient_details.php');
      setUser(result.data.phpresult);
    } catch (error) {
      // console.error("Error fetching user data:", error);
    } 
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    // Initialize DataTable only after user data has been set
    if (user.length > 0 && !$.fn.DataTable.isDataTable('#example')) {
      $('#example').DataTable({
        paging: true,
        searching: true,
        scrollY: '400px',
        scrollX: true,
        scrollCollapse: true,
        responsive: true,
      });
    }

    // Cleanup function to destroy DataTable when the component unmounts or user changes
    return () => {
      if ($.fn.DataTable.isDataTable('#example')) {
        $('#example').DataTable().destroy();
      }
    };
  }, [user]); // Re-run when 'user' data changes


  //--------------------------------------------------



  return (
    <div className="container mt-5">
      <div className="table-responsive">
        <h2>Patient Details</h2>
        <div className='container-table'>
        <table id="example" className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Full name</th>
              <th>Age</th>
              <th>Phone number</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {user.map((res, index) => (
              <tr key={index}>
                <td>{res.full_name}</td>
                <td>{res.age}</td>
                <td>{res.phone_number}</td>
                <td>{res.email}</td>
                <td><button value={res.patient_id} onClick={patientIdFunction} className="btn btn-outline-primary" id="select_exe">Select Exe</button></td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      </div>
    </div>
  );
};

